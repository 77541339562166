import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import * as $ from 'jquery';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  @ViewChild('feature',{ read: true, static: false })
  featureRef: ElementRef;
  Tag="HomepageComponent"
  constructor(public  element:ElementRef) { }
  toTop(event) {
  //  CommonMethods.showLog(this.Tag,'Page Offset : '+window.pageYOffset)
  //  CommonMethods.showLog(this.Tag,'Page Offset : '+window.innerHeight)
  
     var  nav = $('nav')
      , nav_height = nav.outerHeight();
      $('html, body').animate({
        scrollTop: $('#how-its-work').offset().top - nav_height+25
       
      },0);
 
    
  }
  gotoAndroid()
  {
    // CommonMethods.showLog(this.Tag,'Working')
    window.open("https://play.google.com/store/apps/details?id=com.napworks.suggestme", "_blank");
  }
  gotoIOS(){
    window.open("https://itunes.apple.com/us/app/green-shuttle-ng/id1459646969?ls=1&mt=8", "_blank");
  }

  ngOnInit(): void {
  }

}
