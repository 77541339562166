import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import{BlankComponent} from './layouts/blank/blank.component'
import{FullComponent} from './layouts/full/full.component'
import{VoiceChangerComponent}from './ComponentPackages/voice-changer/voice-changer.component'
import{TeamConditionComponent} from './ComponentPackages/team-condition/team-condition.component'
import{PrivacyPolicyComponent} from './ComponentPackages/privacy-policy/privacy-policy.component'
import{ShareComponent} from './ComponentPackages/share/share.component'
import{FaqComponent} from './ComponentPackages/faq/faq.component'
import{AppFaqComponent} from './ComponentPackages/app-faq/app-faq.component'
const routes: Routes = [
    
  { path: '', redirectTo: 'home', pathMatch: 'full' },

  {
    path: 'home',
    component: FullComponent,
    children: [
    
    ]
  },
  {
    path: '',
    component: BlankComponent,
    children: [
      { path: 'terms', component: TeamConditionComponent },
      { path: 'privacy', component: PrivacyPolicyComponent },
      { path: 'share/:name/:username', component: ShareComponent },
      { path: 'faq-app', component: AppFaqComponent },
    ]

  }, 
  // {
  //   path: '',
  //   component: BlankComponent,
  //   children: [
  //     { path: 'faq', component: FaqComponent },
  //     // { path: 'privacy', component: PrivacyPolicyComponent },
  //     // { path: 'share/:name/:username', component: ShareComponent },
  //   ]

  // },
  { path: '***', redirectTo: 'home' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
