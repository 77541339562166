<!-- <div class="footer" style="margin-top:110px;">
    <div class="container-fluid">
        <p>Keep In Touch</p>
        <div class="socialicon">
            <ul style="padding: unset;">
                <li> <i style="color:#3b5998;    font-size: 25px;margin-bottom: 6px; " class="fa fa-facebook-official" aria-hidden="true"></i> <span style="    padding: 9px;
                vertical-align: sub;" (click)="gotoFacebook()">Facebook</span> </li>
                <li> <i style="color:#00acee;    font-size: 25px; " class="fa fa-twitter" aria-hidden="true"></i> <span style="    padding: 9px;
                vertical-align: sub;" (click)="gotoTwitter()">Twitter</span> </li>
          
                <li> <img src="assets/img/Instagram.png" alt="" style="width:30px;margin-top: 6px;"> <span style="    padding: 9px;
                vertical-align: sub;" (click)="gotoInsta()">Instagram</span> </li>
                <li> <i style="color:#c4302b;    font-size: 25px; " class="fa fa-youtube-play" aria-hidden="true"></i> <span style="    padding: 9px;
                vertical-align: sub;" (click)="gotoYoutube()">YouTube</span> </li>
         
            </ul>
        </div>
        <p>
    
    </div>


</div> -->
<div class="footer py-5 border-top text-center">
    <div class="container">
        <div class="row mb-5">
            <div class="col-12">
                <p class="mb-0">
                    <a (click)="gotoOuterLink('facebook')" class="p-3 facebook"><i class="fa fa-facebook" aria-hidden="true"></i></a>
                    <a (click)="gotoOuterLink('instagram')" class="p-3 instagram"><i class="fa fa-instagram" aria-hidden="true"></i></a>
                    <!-- <a href="#" class="p-3 twitter"><i class="fa fa-twitter" aria-hidden="true"></i></a>
                    <a href="#" class="p-3 linkedin"><i class="fa fa-linkedin" aria-hidden="true"></i></a> -->
                </p>
            </div>
        </div>
    </div>
</div>