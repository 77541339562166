<div class="site-section bg-light">
    <div class="container">
        <div class="row mb-5">
            <div class="col-12 text-center">
                <h2 class="section-title mb-3" style="padding: 40px;" data-aos="fade-right">How To Suggest?</h2>
            </div>
        </div>
        <div class="row mb-5 site-section ">
            <div class="col-lg-7" data-aos="fade-down-right" data-aos-duration="500" style="text-align: center;">
                <img src="assets/img/images/Mian_Home_Logo.png" alt="Image" class="img-fluid">
            </div>
            <div class="col-lg-5 pl-lg-5 ml-auto mt-md-5  mycol" data-aos="fade-up" data-aos-duration="500" style="margin-top: unset !important;">
                <h2 class="text-black" saty>Record Voice</h2>
                <p class="mb-4">Suggest me allow you to record any voice, get it converted into a non-recognisable voice which you can listen to before sending to another user.</p>
            </div>
        </div>

        <div class="mt-5 row mb-5 site-section search-section">
            <div class="col-lg-7 order-1 order-lg-2" data-aos="fade-right" data-aos-duration="700" style="text-align: center;">
                <img src="./assets/img/images/search_user_icon.png" alt="Image" class="img-fluid">
            </div>
            <div class="col-lg-5 pr-lg-5 mr-auto mt-5 order-2 order-lg-1 mycol" data-aos="fade-up-left" data-aos-duration="700">
                <h2 class="text-black">Search User</h2>
                <p class="mb-4">Each user is linked to a unique username. App allows you to search for a user using username. After search you will be able to confirm the identity of the recipient by their profile picture and name.
                </p>

            </div>
        </div>
        <div class="row mb-5 site-section ">
            <div class="col-lg-7" data-aos="fade-down-right" data-aos-duration="900" style="text-align: center;">
                <img src="assets/img/images/send_suggestion_icon.png" alt="Image" class="img-fluid">
            </div>
            <div class="col-lg-5 pl-lg-5 ml-auto mt-md-5 mycol" data-aos="fade-up" data-aos-duration="900" style="margin-top: unset !important;">
                <h2 class="text-black">Send Suggestion</h2>
                <p class="mb-4">Once you select a user to share a voice message, click the “Share Suggestion” button and the suggestion will be shared. Receivers will get notified that a new suggestion has been received but your identity will not be disclosed.
                </p>

            </div>
        </div>

        <div class="mt-5 row mb-5 site-section ">
            <div class="col-lg-7 order-1 order-lg-2" data-aos="zoom-out-right" data-aos-duration="1100" style="text-align: center;">
                <img src="./assets/img/images/listen_suggestion_icon.png" alt="Image" class="img-fluid">
            </div>
            <div class="col-lg-5 pr-lg-5 mr-auto mt-5 order-2 order-lg-1 mycol" data-aos="fade-up" data-aos-duration="1100">
                <h2 class="text-black">Listen Suggestion</h2>
                <p class="mb-4">From the list of suggestions you can select any of the voice messages and listen to it. You can like or dislike it and the sender will be notified that your suggestion is being liked or disliked.

                </p>
            </div>
        </div>
        <div class="row mb-5 site-section ">
            <div class="col-lg-7" data-aos="fade-down-right" data-aos-duration="1200" style="text-align: center;">
                <img src="assets/img/images/share_icon.svg" alt="Image" class="img-fluid">

            </div>
            <div class="col-lg-5 pl-lg-5 ml-auto mt-md-5 mycol" data-aos="fade-up" data-aos-duration="1200" style="margin-top: unset !important;">
                <h2 class="text-black">Share Profile</h2>
                <p class="mb-4">You can share your profile on any platform in the form of an image and url of your profile which will enable another user to redirect to your profile and share their suggestions directly.
                </p>

            </div>
        </div>
        <div class="row mb-5">
            <div class="col-12 text-center">
                <h2 class="section-title mb-3" style="padding: 40px;" data-aos="fade-right"> Additional Features</h2>
            </div>
        </div>
        <!-- <div class="mt-5 row mb-5 site-section ">
            <div class="col-lg-7 order-1 order-lg-2" data-aos="zoom-out-right" data-aos-duration="1100" style="text-align: center;position:relative">
                <img src="assets/img/images/send_suggestion_icon.png" alt="Image" class="img-fluid">
            </div>
            <div class="col-lg-5 pr-lg-5 mr-auto mt-5 order-2 order-lg-1">
                <h2 class="text-black" data-aos="fade-up" data-aos-duration="1100">Extra Features </h2>
                <h6 class="featureList" data-aos="fade-down" data-aos-easing="linear" data-aos="fade-up" data-aos-duration="3000"> <i class="fa fa-check-square-o" aria-hidden="true"></i>Block User</h6>
                <p class="mb-4" data-aos="fade-up" data-aos-duration="1100">‘Suggest Me’ allows you to block users so that you will not get suggestions any more from that user.
                </p>
                <h6 class="featureList" data-aos="fade-up" data-aos-duration="1100"> <i class="fa fa-check-square-o" aria-hidden="true"></i>Report Suggestion</h6>
                <p class="mb-4" data-aos="fade-up" data-aos-duration="1100">If you got a suggestion from a user that is either spam or it hurts you. Then you can report that suggestion. We will take appropriate action against that user.
                </p>
                <h6 class="featureList" data-aos="fade-up" data-aos-duration="1100"> <i class="fa fa-check-square-o" aria-hidden="true"></i>Like/Dislike Suggestion
                </h6>
                <p class="mb-4" data-aos="fade-up" data-aos-duration="1100">You can like or dislike any suggestion so that the sender will get to know that his/her suggestion is liked. So next time he/she can send new suggestions accordingly.

                </p>

            </div>
        </div> -->

        <div class="row align-items-stretch" style="overflow-x: hidden; overflow-y: hidden;">
            <div class="col-md-6 col-lg-4 mb-4 mb-lg-4" data-aos="fade-up" data-aos-duration="1000">

                <div class="unit-4 d-block">
                    <div class="unit-4-icon mb-3">
                        <div class="imageDiv">
                            <img src="./assets/img/images/block.svg" alt="">
                        </div>
                    </div>
                    <div>
                        <h3>Block User</h3>
                        <p>‘Suggest Me’ allows you to block users so that you will not get any suggestions from that user. </p>
                    </div>
                </div>

            </div>
            <div class="col-md-6 col-lg-4 mb-4 mb-lg-4" data-aos="fade-up" data-aos-duration="1000">

                <div class="unit-4 d-block">
                    <div class="unit-4-icon mb-3">
                        <div class="imageDiv">
                            <img src="./assets/img/images/report.svg" alt="">
                        </div>
                    </div>
                    <div>
                        <h3>Report Suggestion</h3>
                        <p>If you got a suggestion from a user that it seems to be spam, harassing, communal or threatening, then you can report it. An appropriate action will be taken against that user.</p>
                    </div>
                </div>

            </div>
            <div class="col-md-6 col-lg-4 mb-4 mb-lg-4" data-aos="fade-up" data-aos-duration="1000">

                <div class="unit-4 d-block">
                    <div class="unit-4-icon mb-3">
                        <div class="imageDiv">
                            <img src="./assets/img/images/LIke Dislike.svg" alt="">
                        </div>
                    </div>
                    <div>
                        <h3>Like/Dislike Suggestion</h3>
                        <p>You can like or dislike any suggestion and the sender will get to know that his/her suggestion is being liked/disliked. This will help the users to send new suggestions accordingly.</p>
                    </div>
                </div>

            </div>


        </div>
    </div>
</div>