import { Component, OnInit } from '@angular/core';
import { CommonMethods } from 'src/app/commonMethod/common-method';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  constructor() { }

  gotoOuterLink(buttonType:any){
    var tag="Hello"
     CommonMethods.showLog(tag,"BUttin Type:- "+buttonType)
   
    if(buttonType =='facebook')
    {
      window.open('https://www.facebook.com/Suggest-Me-106030864397827','_blank')
    }else{
      window.open('https://www.instagram.com/napworks1503/','_blank')
    }
  }
  // gotoYoutube(){
  //   window.open('https://www.youtube.com/channel/UCf_VANjrgmwURMvKQVRfAlQ?view_as=subscriber','_blank')
  // }
  // gotoTwitter(){
  //   // window.open('','_blank')
  // }
  // gotoFacebook(){
  //   // window.open('','_blank')
  // }
  ngOnInit(): void {
  }

}
