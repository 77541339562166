import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-share',
  templateUrl: './share.component.html',
  styleUrls: ['./share.component.css']
})
export class ShareComponent implements OnInit {

     userName:string;
     name:string;
  constructor(public activatedRouter:ActivatedRoute) {
      this.userName="";
      this.name="";  
    this.activatedRouter.params.subscribe(params =>{
         this.userName=params['username'];
         this.name=params['name'];
      })
   }

  ngOnInit(): void {
  }

  gotoAndroid()
  {
    // CommonMethods.showLog(this.Tag,'Working')
    window.open("https://play.google.com/store/apps/details?id=com.napworks.suggestme", "_blank");
  }
}
