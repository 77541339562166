<div class="site-section bg-light" id="features-section">
    <div class="container">
        <div class="row mb-5">
            <div class="col-12 text-center">
                <h2 class="section-title mb-3">How To Suggest?</h2>
            </div>
        </div>
        <div class="row align-items-stretch">
            <div class="col-md-6 col-lg-4 mb-4 mb-lg-4" data-aos="fade-up" data-aos-duration="1000">

                <div class="unit-4 d-block">
                    <div class="unit-4-icon mb-3">
                        <span class="icon-wrap"><span class="text-primary icon-autorenew"></span></span>
                    </div>
                    <div>
                        <h3>Business Consulting</h3>
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Perferendis quis molestiae vitae eligendi at.</p>
                        <p><a href="#">Learn More</a></p>
                    </div>
                </div>

            </div>
            <div class="col-md-6 col-lg-4 mb-4 mb-lg-4" data-aos="fade-up" data-aos-duration="1000">

                <div class="unit-4 d-block">
                    <div class="unit-4-icon mb-3">
                        <span class="icon-wrap"><span class="text-primary icon-store_mall_directory"></span></span>
                    </div>
                    <div>
                        <h3>Market Analysis</h3>
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Perferendis quis molestiae vitae eligendi at.</p>
                        <p><a href="#">Learn More</a></p>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-lg-4 mb-4 mb-lg-4" data-aos="fade-up" data-aos-duration="1000">
                <div class="unit-4 d-block">
                    <div class="unit-4-icon mb-3">
                        <span class="icon-wrap"><span class="text-primary icon-shopping_basket"></span></span>
                    </div>
                    <div>
                        <h3>User Monitoring</h3>
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Perferendis quis molestiae vitae eligendi at.</p>
                        <p><a href="#">Learn More</a></p>
                    </div>
                </div>
            </div>


            <div class="col-md-6 col-lg-4 mb-4 mb-lg-4" data-aos="fade-up" data-aos-duration="1000">
                <div class="unit-4 d-block">
                    <div class="unit-4-icon mb-3">
                        <span class="icon-wrap"><span class="text-primary icon-settings_backup_restore"></span></span>
                    </div>
                    <div>
                        <h3>Insurance Consulting</h3>
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Perferendis quis molestiae vitae eligendi at.</p>
                        <p><a href="#">Learn More</a></p>
                    </div>
                </div>
            </div>

            <div class="col-md-6 col-lg-4 mb-4 mb-lg-4" data-aos="fade-up" data-aos-duration="1000">
                <div class="unit-4 d-block">
                    <div class="unit-4-icon mb-3">
                        <span class="icon-wrap"><span class="text-primary icon-sentiment_satisfied"></span></span>
                    </div>
                    <div>
                        <h3>Customer Satistified</h3>
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Perferendis quis molestiae vitae eligendi at.</p>
                        <p><a href="#">Learn More</a></p>
                    </div>
                </div>


            </div>

            <div class="col-md-6 col-lg-4 mb-4 mb-lg-4" data-aos="fade-up" data-aos-duration="1000">
                <div class="unit-4 d-block">
                    <div class="unit-4-icon mb-3">
                        <span class="icon-wrap"><span class="text-primary icon-power"></span></span>
                    </div>
                    <div>
                        <h3>Plug &amp; Play</h3>
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Perferendis quis molestiae vitae eligendi at.</p>
                        <p><a href="#">Learn More</a></p>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>

<div class="feature-big">
    <div class="container">
        <div class="row mb-5 site-section border-bottom">
            <div class="col-lg-7" data-aos="fade-down-right">
                <img src="./assets/img/do_ui_kit_download_cta_floating_devices-2x.png" alt="Image" class="img-fluid">
            </div>
            <div class="col-lg-5 pl-lg-5 ml-auto mt-md-5" data-aos="fade-up" data-aos-duration="1000">
                <h2 class="text-black">Create interactive prototypes</h2>
                <p class="mb-4">Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatem neque nisi architecto autem molestias corrupti officia veniam.</p>
                <ul class="ul-check mb-5 list-unstyled success">
                    <li>Adipisci excepturi aliquam</li>
                    <li>Deleniti labore reiciendis</li>
                </ul>
                <div class="author-box">
                    <div class="d-flex mb-4">
                        <div class="mr-3">
                            <img src="./assets/img/person_1.jpg" alt="Image" class="img-fluid rounded-circle">
                        </div>
                        <div class="mr-auto text-black">
                            <strong class="font-weight-bold mb-0">Amalia G.</strong> <br> Co-Founder, XYZ Inc.
                        </div>
                    </div>
                    <blockquote>&ldquo;Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ducimus vitae ipsa asperiores inventore aperiam iure?&rdquo;</blockquote>
                </div>
            </div>
        </div>

        <div class="mt-5 row mb-5 site-section ">
            <div class="col-lg-7 order-1 order-lg-2" data-aos="zoom-out-right">
                <img src="./assets/img/do_ui_kit_hero_floating_devices-2x.png" alt="Image" class="img-fluid">
            </div>
            <div class="col-lg-5 pr-lg-5 mr-auto mt-5 order-2 order-lg-1" data-aos="fade-up" data-aos-duration="1000">
                <h2 class="text-black">Create interactive prototypes</h2>
                <p class="mb-4">Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatem neque nisi architecto autem molestias corrupti officia veniam</p>
                <ul class="ul-check mb-5 list-unstyled success">
                    <li>Laborum enim quasi at modi</li>
                    <li>Ad at tempore</li>
                </ul>


                <div class="author-box">
                    <div class="d-flex mb-4">
                        <div class="mr-3">
                            <img src="./assets/img/person_4.jpg" alt="Image" class="img-fluid rounded-circle">
                        </div>
                        <div class="mr-auto text-black">
                            <strong class="font-weight-bold mb-0">Darren K.</strong> <br> Co-Founder, XYZ Inc.
                        </div>
                    </div>
                    <blockquote>&ldquo;Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ducimus vitae ipsa asperiores inventore aperiam iure?&rdquo;</blockquote>
                </div>
            </div>
        </div>
    </div>
</div>