<div class="site-section bg-light" style="background: #fff !important; ">
    <div class="container">

        <h2 style="text-align: center;margin-top: 30px;">Terms and conditions</h2>
        <p>These terms and conditions (&quot;Terms&quot;, &quot;Agreement&quot;) are an agreement between Mobile Application Developer (&quot;Mobile Application Developer&quot;, &quot;us&quot;, &quot;we&quot; or &quot;our&quot;) and you (&quot;User&quot;,
            &quot;you&quot; or &quot;your&quot;). This Agreement sets forth the general terms and conditions of your use of the Suggest Me mobile application and any of its products or services (collectively, &quot;Mobile Application&quot; or &quot;Services&quot;).</p>
        <p><strong>Accounts and membership </strong></p>
        <p>You must be at least 13 years of age to use this Mobile Application. By using this Mobile Application and by agreeing to this Agreement you warrant and represent that you are at least 13 years of age. If you create an account in the Mobile Application,
            you are responsible for maintaining the security of your account and you are fully responsible for all activities that occur under the account and any other actions taken in connection with it. We may, but have no obligation to, monitor and
            review new accounts before you may sign in and use our Services. Providing false contact information of any kind may result in the termination of your account. You must immediately notify us of any unauthorized uses of your account or any
            other breaches of security. We will not be liable for any acts or omissions by you, including any damages of any kind incurred as a result of such acts or omissions. We may suspend, disable, or delete your account (or any part thereof) if
            we determine that you have violated any provision of this Agreement or that your conduct or content would tend to damage our reputation and goodwill. If we delete your account for the foregoing reasons, you may not re-register for our Services.
            We may block your email address and Internet protocol address to prevent further registration.</p>
        <p><strong>User content</strong></p>

        <p>We do not own any data, information or material (&quot;Content&quot;) that you submit in the Mobile Application in the course of using the Service. You shall have sole responsibility for the accuracy, quality, integrity, legality, reliability,
            appropriateness, and intellectual property ownership or right to use of all submitted Content. We may, but have no obligation to, monitor and review Content in the Mobile Application submitted or created using our Services by you. Unless specifically
            permitted by you, your use of the Mobile Application does not grant us the license to use, reproduce, adapt, modify, publish or distribute the Content created by you or stored in your user account for commercial, marketing or any similar purpose.
            But you grant us permission to access, copy, distribute, store, transmit, reformat, display and perform the Content of your user account solely as required for the purpose of providing the Services to you. Without limiting any of those representations
            or warranties, we have the right, though not the obligation, to, in our own sole discretion, refuse or remove any Content that, in our reasonable opinion, violates any of our policies or is in any way harmful or objectionable.</p>

        <p><strong>Backups</strong></p>
        <p>We are not responsible for Content residing in the Mobile Application. In no event shall we be held liable for any loss of any Content. It is your sole responsibility to maintain appropriate backup of your Content. Notwithstanding the foregoing,
            on some occasions and in certain circumstances, with absolutely no obligation, we may be able to restore some or all of your data that has been deleted as of a certain date and time when we may have backed up data for our own purposes. We
            make no guarantee that the data you need will be available.</p>

        <p><strong>Links to other mobile applications</strong></p>
        <p>Although this Mobile Application may link to other mobile applications, we are not, directly or indirectly, implying any approval, association, sponsorship, endorsement, or affiliation with any linked mobile application, unless specifically stated
            herein. Some of the links in the Mobile Application may be &quot;affiliate links&quot;. This means if you click on the link and purchase an item, Mobile Application Developer will receive an affiliate commission. We are not responsible for
            examining or evaluating, and we do not warrant the offerings of, any businesses or individuals or the content of their mobile applications. We do not assume any responsibility or liability for the actions, products, services, and content of
            any other third-parties. You should carefully review the legal statements and other conditions of use of any mobile application which you access through a link from this Mobile Application. Your linking to any other off-site mobile applications
            is at your own risk.</p>
        <p><strong>Changes and amendments</strong></p>
        <p>We reserve the right to modify this Agreement or its policies relating to the Mobile Application or Services at any time, effective upon posting of an updated version of this Agreement in the Mobile Application. When we do, we will revise the
            updated date at the bottom of this page. Continued use of the Mobile Application after any such changes shall constitute your consent to such changes. Policy was created with <a style="color:inherit" target="_blank" title="Create terms and conditions"
                href="https://www.websitepolicies.com/terms-and-conditions-generator">WebsitePolicies</a>.</p>


        <p><strong>Advertising</strong></p>
        <p> This type of service allows User Data to be utilized for advertising communication purposes displayed in the form of banners and other advertisements on this Application, possibly based on User interests. This does not mean that all Personal Data
            are used for this purpose. Information and conditions of use are shown below. Some of the services listed below may use Cookies to identify Users or they may use the behavioral retargeting technique, i.e. displaying ads tailored to the User’s
            interests and behavior, including those detected outside this Application. For more information, please check the privacy policies of the relevant services. In addition to any opt out offered by any of the services below, the User may opt
            out of a third-party service's use of cookies by visiting the Network Advertising Initiative opt-out page. AdMob (AdMob Google Inc.) AdMob is an advertising service provided by AdMob Google Inc. In order to understand Google's use of Data,
            consult Google's partner policy. Personal Data collected: Cookies; unique device identifiers for advertising (Google Advertiser ID or IDFA, for example); Usage Data.
        </p>
        <p><strong>Acceptance of these terms</strong></p>
        <p>You acknowledge that you have read this Agreement and agree to all its terms and conditions. By using the Mobile Application or its Services you agree to be bound by this Agreement. If you do not agree to abide by the terms of this Agreement,
            you are not authorized to use or access the Mobile Application and its Services.</p>
        <p><strong>Contacting us</strong></p>
        <p>If you would like to contact us to understand more about this Agreement or wish to contact us concerning any matter relating to it, you may send an email to c&#111;&#110;t&#97;&#99;t&#64;&#110;a&#112;&#119;&#111;&#114;&#107;&#115;.in</p>
        <p>This document was last updated on April 9 ,2020</p>

    </div>
</div>