<header>
    <section class="header-content">
        <div class="leftcontent">
            <div>
                <img src="assets/img/images/text logo.png" alt="" style="width: 131px;
                padding: 20px;"></div>
            <!-- <h1 class="header-title animate-pop-in"><a style="color: #D6504F;">Suggest Me</a></h1> -->
            <h1 class="header-title animate-pop-in"> with converted voice
            </h1>
            <h3 class="header-subtitle animate-pop-in">
                <!-- Suggest your opinion with your friends, family members or any random person with their username in the form of changed voice.<a style="color:#66b2b2;font-weight: bold;">+2347014805070</a> </h3> -->
                Suggest your opinion with your friends, family members or any random person with their username in the form of a changed voice without disclosing your identity. </h3>
            <p>Download Now</p>
            <div class="header-button animate-pop-in divbutton">
                <button class="android" (click)="gotoAndroid()"><span><img src="assets/img/images/googleplay_png.png"  ></span></button>
            </div>
        </div>
        <div class="rightcontent animate-pop-in ">
            <img src="assets/img/images/Mian_Home_Logo.png">
        </div>

    </section>
    <section class="header-down-arrow">
        <img src="./assets/img/images/downarrow.png" width="40" (click)="toTop($event)" style="cursor:pointer;">
    </section>
</header>