<div class="site-section bg-light">
    <div class="container">
        <div class="row mb-5">
            <div class="col-12 text-center">
                <h2 class="section-title mb-3" style="padding: 25px;padding-bottom: unset;" data-aos="fade-down">FAQ</h2>
            </div>
        </div>
        <div class="mt-12 row mb-12 site-section ">

            <div class="faq" id="accordion" role="tablist" aria-multiselectable="true">
                <div class="faq-q">
                    <div class="card">
                        <div class="card-header" role="tab" id="accordionHeadingTwo">
                            <div class="mb-0 row">
                                <div class="col-12 no-padding accordion-head">
                                    <a data-toggle="collapse" data-parent="#accordion" href="#accordionBodyTwo" aria-expanded="false" aria-controls="accordionBodyTwo" class="collapsed ">
                                        <i class="fa fa-angle-down" aria-hidden="true"></i>
                                        <h3>How To Suggest?</h3>
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div id="accordionBodyTwo" class="collapse" role="tabpanel" aria-labelledby="accordionHeadingTwo" aria-expanded="false" data-parent="accordion">
                            <div class="card-block col-12">
                                <ul class="mylist">
                                    <li> <strong>Record Voice</strong>
                                        <div class="insideText">
                                            Suggest me allow you to record any voice, get it converted into a non-recognisable voice which you can listen to before sending to another user.
                                        </div>
                                    </li>
                                    <li> <strong>Search User</strong>
                                        <div class="insideText">
                                            Each user is linked to a unique username. App allows you to search for a user using username. After search you will be able to confirm the identity of the recipient by their profile picture and name.
                                        </div>
                                    </li>
                                    <li> <strong>Send Suggestion</strong>
                                        <div class="insideText">
                                            Once you select a user to share a voice message, click the “Send Suggestion” button and the suggestion will be shared. Receivers will get notified that a new suggestion has been received but your identity will not be disclosed.
                                        </div>
                                    </li>
                                    <li> <strong>Listen Suggestion</strong>
                                        <div class="insideText">
                                            Once you select a user to share a voice message, click the “Send Suggestion” button and the suggestion will be shared. Receivers will get notified that a new suggestion has been received but your identity will not be disclosed.
                                        </div>
                                    </li>

                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="faq-q">
                    <div class="card">
                        <div class="card-header" role="tab" id="accordionHeadingOne">
                            <div class="mb-0 row">
                                <div class="col-12  accordion-head">
                                    <a data-toggle="collapse" data-parent="#accordion" href="#accordionBodyOne" aria-expanded="false" aria-controls="accordionBodyOne" class="collapsed ">
                                        <i class="fa fa-angle-down" aria-hidden="true"></i>
                                        <h3>How to record voice?</h3>

                                    </a>
                                </div>
                            </div>
                        </div>

                        <div id="accordionBodyOne" class="collapse" role="tabpanel" aria-labelledby="accordionHeadingOne" aria-expanded="false" data-parent="accordion">
                            <div class="card-block col-12">
                                <ul class="mylist">
                                    <li>Login into the app.</li>
                                    <li>App shows a mic button at the bottom.</li>
                                    <li>Hold that button until you are recording it. When you release it, your recording will be saved.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="faq-q">
                    <div class="card">
                        <div class="card-header" role="tab" id="accordionHeading7">
                            <div class="mb-0 row">
                                <div class="col-12 no-padding accordion-head">
                                    <a data-toggle="collapse" data-parent="#accordion" href="#accordionBody7" aria-expanded="false" aria-controls="accordionBody7" class="collapsed ">
                                        <i class="fa fa-angle-down" aria-hidden="true"></i>
                                        <h3>How to search users?</h3>
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div id="accordionBody7" class="collapse" role="tabpanel" aria-labelledby="accordionHeading7" aria-expanded="false" data-parent="accordion">
                            <div class="card-block col-12">
                                <ul class="mylist">
                                    <li>Login into the app.</li>
                                    <li>Home screen shows you a search view.</li>
                                    <li>Enter username of any user and press search button.</li>
                                    <li>App will show you name, username and profile pic.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="faq-q">
                    <div class="card">
                        <div class="card-header" role="tab" id="accordionHeading7">
                            <div class="mb-0 row">
                                <div class="col-12 no-padding accordion-head">
                                    <a data-toggle="collapse" data-parent="#accordion" href="#accordionBody8" aria-expanded="false" aria-controls="accordionBody7" class="collapsed ">
                                        <i class="fa fa-angle-down" aria-hidden="true"></i>
                                        <h3>How to send a suggestion?</h3>
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div id="accordionBody8" class="collapse" role="tabpanel" aria-labelledby="accordionHeading8" aria-expanded="false" data-parent="accordion">
                            <div class="card-block col-12">
                                <ul class="mylist">
                                    <li>Firstly record your suggestion.</li>
                                    <li>Search username.</li>
                                    <li>After searching, the “Send Suggestion” button will be visible.</li>
                                    <li>Press that button to send your suggestion.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="faq-q">
                    <div class="card">
                        <div class="card-header" role="tab" id="accordionHeading7">
                            <div class="mb-0 row">
                                <div class="col-12 no-padding accordion-head">
                                    <a data-toggle="collapse" data-parent="#accordion" href="#accordionBody9" aria-expanded="false" aria-controls="accordionBody7" class="collapsed ">
                                        <i class="fa fa-angle-down" aria-hidden="true"></i>
                                        <h3>How to share username?</h3>
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div id="accordionBody9" class="collapse" role="tabpanel" aria-labelledby="accordionHeading9" aria-expanded="false" data-parent="accordion">
                            <div class="card-block col-12">
                                <ul class="mylist">
                                    <li>Login into the app.</li>
                                    <li>Go to settings from side navigation.</li>
                                    <li>Click “Get Suggestions” tab.</li>
                                    <li>New screen will open showing your Name, Username and profile pic (if login with facebook).</li>
                                    <li>Click the “Share Now” button to share your username on any social media platform, messages or email.</li>
                                    <li>By clicking “Share Now” your account url will be copied to clipboard.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="faq-q">
                    <div class="card">
                        <div class="card-header" role="tab" id="accordionHeading7">
                            <div class="mb-0 row">
                                <div class="col-12 no-padding accordion-head">
                                    <a data-toggle="collapse" data-parent="#accordion" href="#accordionBody10" aria-expanded="false" aria-controls="accordionBody7" class="collapsed ">
                                        <i class="fa fa-angle-down" aria-hidden="true"></i>
                                        <h3>How replies work?</h3>
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div id="accordionBody10" class="collapse" role="tabpanel" aria-labelledby="accordionHeading10" aria-expanded="false" data-parent="accordion">
                            <div class="card-block col-12">
                                <ul class="mylist">
                                    <li>You can reply to any of the received suggestions.Your suggestion will be directly sent to that sender and he/she can’t identify it.</li>
                                    <li>Each reply reduces 1 reply from your account.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="faq-q">
                    <div class="card">
                        <div class="card-header" role="tab" id="accordionHeading11">
                            <div class="mb-0 row">
                                <div class="col-12 no-padding accordion-head">
                                    <a data-toggle="collapse" data-parent="#accordion" href="#accordionBody11" aria-expanded="false" aria-controls="accordionBody7" class="collapsed ">
                                        <i class="fa fa-angle-down" aria-hidden="true"></i>
                                        <h3>How many replies did I get on my new account?</h3>
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div id="accordionBody11" class="collapse" role="tabpanel" aria-labelledby="accordionHeading11" aria-expanded="false" data-parent="accordion">
                            <div class="card-block col-12">
                                <ul class="mylist">
                                    <li>On a new account signup, the user will get 10 replies.</li>

                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="faq-q">
                    <div class="card">
                        <div class="card-header" role="tab" id="accordionHeading7">
                            <div class="mb-0 row">
                                <div class="col-12 no-padding accordion-head">
                                    <a data-toggle="collapse" data-parent="#accordion" href="#accordionBody12" aria-expanded="false" aria-controls="accordionBody7" class="collapsed ">
                                        <i class="fa fa-angle-down" aria-hidden="true"></i>
                                        <h3>How do I get more replies?</h3>
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div id="accordionBody12" class="collapse" role="tabpanel" aria-labelledby="accordionHeading12" aria-expanded="false" data-parent="accordion">
                            <div class="card-block col-12">
                                <ul class="mylist">
                                    <li>Each user will get 10 replies daily and your current available replies will continue to be used in future. </li>

                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="faq-q">
                    <div class="card">
                        <div class="card-header" role="tab" id="accordionHeading7">
                            <div class="mb-0 row">
                                <div class="col-12 no-padding accordion-head">
                                    <a data-toggle="collapse" data-parent="#accordion" href="#accordionBody13" aria-expanded="false" aria-controls="accordionBody7" class="collapsed ">
                                        <i class="fa fa-angle-down" aria-hidden="true"></i>
                                        <h3>How to block users?</h3>
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div id="accordionBody13" class="collapse" role="tabpanel" aria-labelledby="accordionHeading13" aria-expanded="false" data-parent="accordion">
                            <div class="card-block col-12">
                                <ul class="mylist">
                                    <li>From the suggestions list, you can block any user. He/she will not be able to send suggestions again. </li>
                                    <li>You can define a reference name for blocked users.</li>

                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="faq-q">
                    <div class="card">
                        <div class="card-header" role="tab" id="accordionHeading7">
                            <div class="mb-0 row">
                                <div class="col-12 no-padding accordion-head">
                                    <a data-toggle="collapse" data-parent="#accordion" href="#accordionBody14" aria-expanded="false" aria-controls="accordionBody7" class="collapsed ">
                                        <i class="fa fa-angle-down" aria-hidden="true"></i>
                                        <h3>How to unblock users?</h3>
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div id="accordionBody14" class="collapse" role="tabpanel" aria-labelledby="accordionHeading14" aria-expanded="false" data-parent="accordion">
                            <div class="card-block col-12">
                                <ul class="mylist">
                                    <li>Login into the app.</li>
                                    <li>Go to settings from side navigation.</li>
                                    <li>Click “Block List” tab.</li>
                                    <li>You can see list of all the blocked users.</li>
                                    <li>Click the “Unblock” button to unblock that user.</li>

                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>

    </div>
</div>