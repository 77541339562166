<app-navbar></app-navbar>


<section class="active" id="home">
    <app-home></app-home>
</section>
<section id="how-its-work">
    <app-how-its-work></app-how-its-work>
</section>
<!-- <section id="faq">
    <app-faq></app-faq>
</section> -->
<section id="contactus">
    <app-contact-us></app-contact-us>
</section>

<app-footer></app-footer>