<div class="site-section bg-light">
    <div class="container">
        <div class="row mb-5">
            <div class="col-12 text-center">
                <h2 class="section-title mb-3" style="padding: 40px;" data-aos="fade-down">Contact Us</h2>
            </div>
        </div>
        <div class="mt-5 row mb-5 site-section ">
            <div class="col-lg-7 order-1 order-lg-2" data-aos="zoom-out-right" style="text-align: center;">
                <img class="imagediv" src="./assets/img/images/Contact Us.svg" alt="Image" class="img-fluid">
            </div>
            <div class="col-lg-5 pr-lg-5 mr-auto mt-5 order-2 order-lg-1" data-aos="fade-up">
                <h2 class="text-black">Keep in touch</h2>
                <p class="mb-4">Feel free to get in touch with us using our email address.
                </p>
                <h5 class="text-black" style="color: #928e8a91 !important;"> <i class="fa fa-envelope" aria-hidden="true"></i>Email</h5>
                <p class="mb-4">contact@napworks.in
                </p>
            </div>
        </div>

    </div>
</div>