import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavbarComponent } from './ComponentPackages/navbar/navbar.component';
import { HomeComponent } from './ComponentPackages/home/home.component';
import { FeatureComponent } from './ComponentPackages/feature/feature.component';
import { FooterComponent } from './ComponentPackages/footer/footer.component';
import { FullComponent } from './layouts/full/full.component';
import { BlankComponent } from './layouts/blank/blank.component';
import { VoiceChangerComponent } from './ComponentPackages/voice-changer/voice-changer.component';
import { AosToken, aos } from './aos';
import { HowItsWorkComponent } from './ComponentPackages/how-its-work/how-its-work.component';
import { ContactUsComponent } from './ComponentPackages/contact-us/contact-us.component';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { TeamConditionComponent } from './ComponentPackages/team-condition/team-condition.component';
import { PrivacyPolicyComponent } from './ComponentPackages//privacy-policy/privacy-policy.component';
import { ShareComponent } from './ComponentPackages/share/share.component';
import { FaqComponent } from './ComponentPackages/faq/faq.component';
import { AppFaqComponent } from './ComponentPackages/app-faq/app-faq.component';
@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    HomeComponent,
    FeatureComponent,
    FooterComponent,
    FullComponent,
    BlankComponent,
    VoiceChangerComponent,
    HowItsWorkComponent,
    ContactUsComponent,
    TeamConditionComponent,
    PrivacyPolicyComponent,
    ShareComponent,
    FaqComponent,
    AppFaqComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule
  ],
  providers: [
      { provide: AosToken, useValue: aos },
      { provide: LocationStrategy, useClass: HashLocationStrategy },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
