import { Component, ElementRef, ViewChild, Renderer2, Inject } from '@angular/core';
import { NavbarComponent } from './ComponentPackages/navbar/navbar.component';
import { Router } from '@angular/router';
import * as $ from 'jquery';
import { CommonMethods } from './commonMethod/common-method';
import { AosToken } from './aos'; 
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent  {
  title = 'voicechnage';
  Tag="AppComponent"
 @ViewChild(NavbarComponent,{ read: true, static: false }) navbar:NavbarComponent
 
  constructor(private renderer : Renderer2,private element : ElementRef,private router: Router,@Inject(AosToken) aos){
    // CommonMethods.showLog(this.Tag,'Window Resolution :'+window.innerWidth)
    aos.init();
    aos.init({ disable: 'mobile' });
  }

  ngOnInit(){
//     var navbar : HTMLElement = this.element.nativeElement.children[0].children[0];
//     this.renderer.listen('window', 'scroll', (event) => {
//       const number = window.scrollY;
//       if (number > 350 || window.pageYOffset > 350) {
//           // add logic
//           navbar.classList.remove('navbar-transparent');
//           document.getElementById("myBtn").style.display = "block";
//           document.getElementById("myBtn").classList.add("lightSpeedIn") ;
//           document.getElementById("myBtn").classList.remove("lightSpeedOut") ;
//           // document.getElementById("bounce").classList.add("bounceIn");
       
//       } else {
//           navbar.classList.add('navbar-transparent');
//           document.getElementById("myBtn").classList.remove("lightSpeedIn") ;
//           document.getElementById("myBtn").classList.add("lightSpeedOut") ; 
//       }
//   });
//   navbar.scrollIntoView({behavior: "smooth", block: "start"})
//   var sections = $('section')
//   , nav = $('nav')
//   , nav_height = nav.outerHeight();
// $(window).on('scroll', function () {
//   var cur_pos = $(this).scrollTop();
//   sections.each(function() {
//     var top = $(this).offset().top - nav_height,
//         bottom = top + $(this).outerHeight();
    
//     if (cur_pos >= top && cur_pos <= bottom) {
//       nav.find('a').removeClass('active');
//       sections.removeClass('active');
      
//       $(this).addClass('active');
//       nav.find('a[href="#'+$(this).attr('id')+'"]').addClass('active');
     
//       $("#mytoggle").attr("aria-expanded","false");
//       document.getElementById('probootstrap-navbar').classList.remove('show')
//       document.getElementById('mytoggle').classList.add('collapsed')
//       document.getElementById('probootstrap-navbar').style.display="none"   
//     }
//   });
// });

// nav.find('a').on('click', function () {
//   var $el = $(this)
//     , id = $el.attr('href');
//   $('html, body').animate({
//     scrollTop: $(id).offset().top - nav_height+10
   
//   }, 0);
//   return false;
// });
// nav.find('a').on('click', function () {
//     $('.navbar-collapse').hide();
//     $("#mytoggle").attr("aria-expanded","false");
//     document.getElementById('probootstrap-navbar').classList.remove('show')
//     document.getElementById('mytoggle').classList.add('collapsed')
//     document.getElementById('probootstrap-navbar').style.display="none"
//     // document.getElementById('probootstrap-navbar').style.background="initial"
 
// });
// nav.find('#mytoggle').on('click', function () {
//    CommonMethods.showLog(this.Tag,'aria Value : '+$("#mytoggle").attr("aria-expanded"))
//    if($("#mytoggle").attr("aria-expanded") == 'true')
//    {
//     document.getElementById('probootstrap-navbar').style.display="none"
//     // document.getElementById('probootstrap-navbar').style.background="initial"
//        CommonMethods.showLog(this.Tag,'If working')
       
//    }else 
//    {
//     CommonMethods.showLog(this.Tag,'else working')
    
//     document.getElementById('probootstrap-navbar').style.display="unset"
//     // document.getElementById('probootstrap-navbar').style.background="rgba(44, 154, 155, 0.47)"
//    } 
// });
  }
  
 
   
  
  
 backToTop() {
  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;
  
}
}
