<div class="site-section bg-light" style="background: #fff !important;">
    <div class="container">
        <div class="crad">
            <div class="card-body" style="background: #fff !important;">
                <div class="imagediv">
                    <img src="./assets/img/images/text logo.png" alt="">
                </div>
                <div class="textdiv">
                    <h2 class="section-title mb-3">{{name}}</h2>
                    <h3 class="section-title mb-3" style="margin-top: unset;">{{userName}}</h3>
                    <p class="longText"> Suggest your opinion with your friends, family members or any random person with their username in the form of a changed voice without disclosing your identity.</p>
                    <!-- <h5 style="text-align: center;">Download Now</h5> -->
                    <div class="header-button divbutton">
                        <button class="android" (click)="gotoAndroid()"><span><img src="assets/img/images/googleplay_png.png"  ></span></button>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>