<!-- <div class="site-section faq-section">
    <div class="container">
        <div class="row mb-5">
            <div class="col-12 text-center">
                <h2 class="title-heading" data-aos="fade-down">FAQ</h2>
            </div>
        </div>



        
    </div>
</div> -->

<div class="site-section bg-light" style="margin-top: 76px; ">
    <div class="container">
        <div class="row mb-5">
            <div class="col-12 text-center">
                <h2 class="section-title mb-3" style="padding: 40px;padding-bottom: unset;" data-aos="fade-down">FAQ</h2>
            </div>
        </div>
        <div class="mt-5 row mb-5 site-section ">
            <!-- <div id="accordion" role="tablist" aria-multiselectable="true">


                <div class="card">
                    <div class="card-header" role="tab" id="accordionHeadingOne">
                        <div class="mb-0 row">
                            <div class="col-12  accordion-head">
                                <a data-toggle="collapse" data-parent="#accordion" href="#accordionBodyOne" aria-expanded="false" aria-controls="accordionBodyOne" class="collapsed ">
                                    <i class="fa fa-angle-down" aria-hidden="true"></i>
                                    <h3>Are there restrictions on the goods that I can send via GetitHomeService??</h3>
                                </a>
                            </div>
                        </div>
                    </div>

                    <div id="accordionBodyOne" class="collapse" role="tabpanel" aria-labelledby="accordionHeadingOne" aria-expanded="false" data-parent="accordion">
                        <div class="card-block col-12">
                            <p>No illegal items, No livestock, commemorative weapons only.</p>
                        </div>
                    </div>
                </div>


                <div class="card">
                    <div class="card-header" role="tab" id="accordionHeadingTwo">
                        <div class="mb-0 row">
                            <div class="col-12 no-padding accordion-head">
                                <a data-toggle="collapse" data-parent="#accordion" href="#accordionBodyTwo" aria-expanded="false" aria-controls="accordionBodyTwo" class="collapsed ">
                                    <i class="fa fa-angle-down" aria-hidden="true"></i>
                                    <h3>How do I pay for the logistics services that I will be using?</h3>
                                </a>
                            </div>
                        </div>
                    </div>

                    <div id="accordionBodyTwo" class="collapse" role="tabpanel" aria-labelledby="accordionHeadingTwo" aria-expanded="false" data-parent="accordion">
                        <div class="card-block col-12">
                            <p>Credit Cards, Cheque and Cash.</p>
                        </div>
                    </div>
                </div>


                <div class="card">
                    <div class="card-header" role="tab" id="accordionHeadingThree">
                        <div class="mb-0 row">
                            <div class="col-12 no-padding accordion-head">
                                <a data-toggle="collapse" data-parent="#accordion" href="#accordionBodyThree" aria-expanded="false" aria-controls="accordionBodyThree" class="collapsed ">
                                    <i class="fa fa-angle-down" aria-hidden="true"></i>
                                    <h3>I want to send a (very) heavy consignment (an anvil, 35 kilograms). How will Select Courier handle this?</h3>
                                </a>
                            </div>
                        </div>
                    </div>

                    <div id="accordionBodyThree" class="collapse" role="tabpanel" aria-labelledby="accordionHeadingThree" aria-expanded="false" data-parent="accordion">
                        <div class="card-block col-12">
                            <p>Equipped for heavy items. All purchases come with truck, necessary equipment to move items and the manpower. Bed Trucks, cargo vans, chevy silvarado and 2 axel box trucks available for moving.</p>
                        </div>
                    </div>
                </div>

                <div class="card">
                    <div class="card-header" role="tab" id="accordionHeading4">
                        <div class="mb-0 row">
                            <div class="col-12 no-padding accordion-head">
                                <a data-toggle="collapse" data-parent="#accordion" href="#accordionBody4" aria-expanded="false" aria-controls="accordionBody4" class="collapsed ">
                                    <i class="fa fa-angle-down" aria-hidden="true"></i>
                                    <h3>What are your office hours?</h3>
                                </a>
                            </div>
                        </div>
                    </div>

                    <div id="accordionBody4" class="collapse" role="tabpanel" aria-labelledby="accordionHeading4" aria-expanded="false" data-parent="accordion">
                        <div class="card-block col-12">
                            <p>We operate 24/7.Please feel free to contact us whenever you like.</p>
                        </div>
                    </div>
                </div>


                <div class="card">
                    <div class="card-header" role="tab" id="accordionHeading5">
                        <div class="mb-0 row">
                            <div class="col-12 no-padding accordion-head">
                                <a data-toggle="collapse" data-parent="#accordion" href="#accordionBody5" aria-expanded="false" aria-controls="accordionBody5" class="collapsed ">
                                    <i class="fa fa-angle-down" aria-hidden="true"></i>
                                    <h3>How can I submit a claim for loss or damage?</h3>
                                </a>
                            </div>
                        </div>
                    </div>

                    <div id="accordionBody5" class="collapse" role="tabpanel" aria-labelledby="accordionHeading5" aria-expanded="false" data-parent="accordion">
                        <div class="card-block col-12">
                            <p>If you have renters insurance, then insurance is included in your hourly purchase. If you do not have renters insurance, you must purchase Lions Group insurance at favorable rates from us. </p>
                        </div>
                    </div>
                </div>


                <div class="card">
                    <div class="card-header" role="tab" id="accordionHeading6">
                        <div class="mb-0 row">
                            <div class="col-12 no-padding accordion-head">
                                <a data-toggle="collapse" data-parent="#accordion" href="#accordionBody6" aria-expanded="false" aria-controls="accordionBody6" class="collapsed ">
                                    <i class="fa fa-angle-down" aria-hidden="true"></i>
                                    <h3>How can I track the status of my shipment during transit?</h3>
                                </a>
                            </div>
                        </div>
                    </div>

                    <div id="accordionBody6" class="collapse" role="tabpanel" aria-labelledby="accordionHeading6" aria-expanded="false" data-parent="accordion">
                        <div class="card-block col-12">
                            <p>All drivers are able to be tracked via a gps tracking app that we will equip you with before transporting your item.</p>
                        </div>
                    </div>
                </div>


                <div class="card">
                    <div class="card-header" role="tab" id="accordionHeading7">
                        <div class="mb-0 row">
                            <div class="col-12 no-padding accordion-head">
                                <a data-toggle="collapse" data-parent="#accordion" href="#accordionBody7" aria-expanded="false" aria-controls="accordionBody7" class="collapsed ">
                                    <i class="fa fa-angle-down" aria-hidden="true"></i>
                                    <h3>What area do you serve? </h3>
                                </a>
                            </div>
                        </div>
                    </div>

                    <div id="accordionBody7" class="collapse" role="tabpanel" aria-labelledby="accordionHeading7" aria-expanded="false" data-parent="accordion">
                        <div class="card-block col-12">
                            <p>25 mile radius of 33021 for a flat rate of $99/hr. Included in this rate is the truck, moving equipment and the men to get the job done.</p>
                        </div>
                    </div>
                </div>
            </div> -->
            <div class="faq" id="accordion" role="tablist" aria-multiselectable="true">
                <div class="faq-q">
                    <div class="card">
                        <div class="card-header" role="tab" id="accordionHeadingTwo">
                            <div class="mb-0 row">
                                <div class="col-12 no-padding accordion-head">
                                    <a data-toggle="collapse" data-parent="#accordion" href="#accordionBodyTwo" aria-expanded="false" aria-controls="accordionBodyTwo" class="collapsed ">
                                        <i class="fa fa-angle-down" aria-hidden="true"></i>
                                        <h3>How To Suggest?</h3>
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div id="accordionBodyTwo" class="collapse" role="tabpanel" aria-labelledby="accordionHeadingTwo" aria-expanded="false" data-parent="accordion">
                            <div class="card-block col-12">
                                <ul class="mylist">
                                    <li> <strong>Record Voice</strong>
                                        <div class="insideText">
                                            Suggest me allow you to record any voice, get it converted into a non-recognisable voice which you can listen to before sending to another user.
                                        </div>
                                    </li>
                                    <li> <strong>Search User</strong>
                                        <div class="insideText">
                                            Each user is linked to a unique username. App allows you to search for a user using username. After search you will be able to confirm the identity of the recipient by their profile picture and name.
                                        </div>
                                    </li>
                                    <li> <strong>Send Suggestion</strong>
                                        <div class="insideText">
                                            Once you select a user to share a voice message, click the “Send Suggestion” button and the suggestion will be shared. Receivers will get notified that a new suggestion has been received but your identity will not be disclosed.
                                        </div>
                                    </li>
                                    <li> <strong>Listen Suggestion</strong>
                                        <div class="insideText">
                                            Once you select a user to share a voice message, click the “Send Suggestion” button and the suggestion will be shared. Receivers will get notified that a new suggestion has been received but your identity will not be disclosed.
                                        </div>
                                    </li>

                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="faq-q">
                    <div class="card">
                        <div class="card-header" role="tab" id="accordionHeadingOne">
                            <div class="mb-0 row">
                                <div class="col-12  accordion-head">
                                    <a data-toggle="collapse" data-parent="#accordion" href="#accordionBodyOne" aria-expanded="false" aria-controls="accordionBodyOne" class="collapsed ">
                                        <i class="fa fa-angle-down" aria-hidden="true"></i>
                                        <h3>How to record voice?</h3>

                                    </a>
                                </div>
                            </div>
                        </div>

                        <div id="accordionBodyOne" class="collapse" role="tabpanel" aria-labelledby="accordionHeadingOne" aria-expanded="false" data-parent="accordion">
                            <div class="card-block col-12">
                                <ul class="mylist">
                                    <li>Login into the app.</li>
                                    <li>App shows a mic button at the bottom.</li>
                                    <li>Hold that button until you are recording it. When you release it, your recording will be saved.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="faq-q">
                    <div class="card">
                        <div class="card-header" role="tab" id="accordionHeading7">
                            <div class="mb-0 row">
                                <div class="col-12 no-padding accordion-head">
                                    <a data-toggle="collapse" data-parent="#accordion" href="#accordionBody7" aria-expanded="false" aria-controls="accordionBody7" class="collapsed ">
                                        <i class="fa fa-angle-down" aria-hidden="true"></i>
                                        <h3>How to search users?</h3>
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div id="accordionBody7" class="collapse" role="tabpanel" aria-labelledby="accordionHeading7" aria-expanded="false" data-parent="accordion">
                            <div class="card-block col-12">
                                <ul class="mylist">
                                    <li>Login into the app.</li>
                                    <li>Home screen shows you a search view.</li>
                                    <li>Enter username of any user and press search button.</li>
                                    <li>App will show you name, username and profile pic.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="faq-q">
                    <div class="card">
                        <div class="card-header" role="tab" id="accordionHeading7">
                            <div class="mb-0 row">
                                <div class="col-12 no-padding accordion-head">
                                    <a data-toggle="collapse" data-parent="#accordion" href="#accordionBody8" aria-expanded="false" aria-controls="accordionBody7" class="collapsed ">
                                        <i class="fa fa-angle-down" aria-hidden="true"></i>
                                        <h3>How to send a suggestion?</h3>
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div id="accordionBody8" class="collapse" role="tabpanel" aria-labelledby="accordionHeading8" aria-expanded="false" data-parent="accordion">
                            <div class="card-block col-12">
                                <ul class="mylist">
                                    <li>Firstly record your suggestion.</li>
                                    <li>Search username.</li>
                                    <li>After searching, the “Send Suggestion” button will be visible.</li>
                                    <li>Press that button to send your suggestion.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="faq-q">
                    <div class="card">
                        <div class="card-header" role="tab" id="accordionHeading7">
                            <div class="mb-0 row">
                                <div class="col-12 no-padding accordion-head">
                                    <a data-toggle="collapse" data-parent="#accordion" href="#accordionBody9" aria-expanded="false" aria-controls="accordionBody7" class="collapsed ">
                                        <i class="fa fa-angle-down" aria-hidden="true"></i>
                                        <h3>How to share username?</h3>
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div id="accordionBody9" class="collapse" role="tabpanel" aria-labelledby="accordionHeading9" aria-expanded="false" data-parent="accordion">
                            <div class="card-block col-12">
                                <ul class="mylist">
                                    <li>Login into the app.</li>
                                    <li>Go to settings from side navigation.</li>
                                    <li>Click “Get Suggestions” tab.</li>
                                    <li>New screen will open showing your Name, Username and profile pic (if login with facebook).</li>
                                    <li>Click the “Share Now” button to share your username on any social media platform, messages or email.</li>
                                    <li>By clicking “Share Now” your account url will be copied to clipboard.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>

    </div>
</div>