<nav class="navbar navbar-expand-lg navbar-dark fixed-top navbar-transparent" id="navbar" color-on-scroll="500">
    <div class="container" style="    margin: unset; padding-left:25px;padding-right:25px;max-width: 100%;">
        <a class="navbar-brand" href="#home" style="border: unset; text-align: left;"><img src="assets/img/images/AppIcon.png" style="width:50px;"></a>

        <button id="mytoggle" class="navbar-toggler ml-auto" type="button" data-toggle="collapse" data-target="#probootstrap-navbar" aria-controls="probootstrap-navbar" aria-expanded="false" aria-label="Toggle navigation">
      <span><i class="fa fa-bars" aria-hidden="true"></i></span>
 
    </button>
        <div class="collapse navbar-collapse" id="probootstrap-navbar">
            <ul class="navbar-nav ml-auto">
                <li class="nav-item"><a class="nav-link active" href="#home" data-toggle="collapse" data-target=".navbar-collapse.show">Home</a></li>
                <li class="nav-item"><a class="nav-link" href="#how-its-work" data-toggle="collapse" data-target=".navbar-collapse.show">How to suggest?</a></li>
                <!-- <li class="nav-item"><a class="nav-link" routerLink="/faq" [routerLinkActive]="['active']" data-toggle="collapse" data-target=".navbar-collapse.show">FAQ</a></li> -->
                <li class="nav-item"><a class="nav-link" href="#contactus" data-toggle="collapse" data-target=".navbar-collapse.show">Contact Us</a></li>
                <!-- <li class="nav-item"><a class="nav-link" routerLink="/team-condition" routerLinkActive="active" data-toggle="collapse" data-target=".navbar-collapse.show">Teams & Condition</a></li>
                <li class="nav-item"><a class="nav-link" routerLink="/privacy-policy" routerLinkActive="active" data-toggle="collapse" data-target=".navbar-collapse.show">Privacy Policy</a></li> -->
            </ul>
        </div>
    </div>
</nav>